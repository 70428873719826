import {
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
} from '@chakra-ui/react';
import ptBR from 'date-fns/locale/pt-BR';
import { ElementType, Fragment } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useFormContext } from 'react-hook-form';
import { MaskProps, Masks } from 'src/components/Form/Masks';
import FormControl from './FormControl';

export type TextFieldProps = InputProps &
  MaskProps & {
    name: string;
    label?: string;
    helperText?: string;
    isDate?: boolean;
    dateOnly?: boolean;
    leftAddon?: React.ReactNode;
    rightAddon?: React.ReactNode;
  };

const TextField = (props: TextFieldProps) => {
  const { control } = useFormContext();
  const { name, mask, onChange, label, helperText, ...fieldProps } = props;

  const leftAddon = props?.leftAddon;
  const rightAddon = props?.rightAddon;

  const InputWrapper = leftAddon || rightAddon ? InputGroup : Fragment;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange: onChangeHookForm, onBlur: onBlurHookForm },
        formState: { isSubmitted },
        fieldState: { isTouched, error },
      }) => {
        const hasError = error && (isTouched || isSubmitted);

        return (
          <FormControl isInvalid={hasError}>
            {label && <FormLabel>{label}</FormLabel>}

            {props?.isDate ? (
              <DatePicker
                disabled={props.isDisabled}
                showTimeSelect={!props.dateOnly}
                timeFormat="p"
                timeIntervals={15}
                timeCaption="Horário"
                dateFormat={props.dateOnly ? 'dd/MM/yyyy' : 'Pp'}
                name={name || ''}
                selected={value || null}
                onChange={(e) => {
                  onChangeHookForm(e);
                }}
                locale={ptBR}
                portalId={`portal-${name}-start`}
                autoComplete="off"
                popperClassName="z-50"
                placeholderText={props?.placeholder || 'DD/MM/AAAA'}
                customInput={<Input />}
              />
            ) : (
              <InputWrapper>
                {leftAddon && leftAddon}
                <Input
                  {...fieldProps}
                  name={name}
                  value={value || ''}
                  onChange={(e) => {
                    onChangeHookForm(e);

                    if (onChange) onChange(e);
                  }}
                  onBlur={(e) => {
                    onBlurHookForm();

                    if (props.onBlur) {
                      props.onBlur(e);
                    }
                  }}
                  type={props.type || 'text'}
                  as={
                    props.as ||
                    (mask
                      ? (Masks[mask] as ElementType<HTMLInputElement>)
                      : 'input')
                  }
                />
                {rightAddon && rightAddon}
              </InputWrapper>
            )}

            {hasError && <FormErrorMessage>{error.message}</FormErrorMessage>}

            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};

export default TextField;
