import {
  ElementType,
  forwardRef,
  ForwardRefRenderFunction,
  RefCallback,
} from 'react';
import { IMaskInput } from 'react-imask';

type CustomProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
};

const CNPJMask: ForwardRefRenderFunction<HTMLElement, CustomProps> = (
  props,
  ref,
) => {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask="00.000.000/0000-00"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
      onAccept={(value) =>
        onChange({ target: { name: props.name, value: value as string } })
      }
    />
  );
};

export default forwardRef(CNPJMask) as ElementType;
